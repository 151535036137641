import React from "react";

function Loading(){
    return (
        <div className="loading page-lazy-loading"></div>
    )
}

export default function Loadable(fn:any){
    var Loader=React.lazy(fn);
    return (
        <React.Suspense fallback={Loading()}><Loader /></React.Suspense>
    );
}