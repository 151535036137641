
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loadable from "./front/components/Loadable";

// import Home from "./front/view/Home";

var Home = Loadable(() => import("./front/view/Home"));
// var Page1 = Loadable(() => import("./front/view/Scene1"));
// var Page2 = Loadable(() => import("./front/view/Scene2"));
// var Page3 = Loadable(() => import("./front/view/Scene3"));
// var Page4 = Loadable(() => import("./front/view/Scene4"));
// var Page5 = Loadable(() => import("./front/view/Scene5"));

var Works= Loadable(() => import("./front/view/Works"));
var AboutUs= Loadable(() => import("./front/view/AboutUs"));

// 后台相关
var AdminLogin=Loadable(() => import("./admin/view/Login"));
var AdminHome=Loadable(() => import("./admin/view/Home"));
var AdminSwiper=Loadable(() => import("./admin/view/AdminSwiper"));
var AdminWorks=Loadable(() => import("./admin/view/Works"));
var WorkEditor=Loadable(() => import("./admin/view/WorkEditor"));
var WorksButtons=Loadable(() => import("./admin/view/WorksButtons"));
var AdminCategories=Loadable(() => import("./admin/view/Categories"));

var AdminSenceHome=Loadable(() => import("./admin/view/scene/Home"));
var AdminMenu=Loadable(() => import("./admin/view/AdminMenu"));
var AdminContact=Loadable(() => import("./admin/view/AdminContact"));

export default function AppRouter() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={Home}>
                    {/* <Route index element={Page1}></Route>
                    <Route path="/scene-2" element={Page2}></Route>    
                    <Route path="/scene-3" element={Page3}></Route>    
                    <Route path="/scene-4" element={Page4}></Route>    
                    <Route path="/scene-5" element={Page5}></Route>     */}
                </Route> 
                <Route path="/works" element={Works}></Route>
                <Route path="/works/:catId" element={Works}></Route>    
                <Route path="/works/:catId/:workIds/:workId" element={Works}></Route>    
                <Route path="/about-us" element={AboutUs}></Route>

                <Route path="/user/login" element={AdminLogin}></Route>
                <Route path="/admin" element={AdminHome}>
                    {/* <Route index element={AdminSenceHome}></Route>                     */}
                    <Route path="scene/:id" element={AdminSenceHome}></Route>                    
                    <Route path="menu" element={AdminMenu}></Route>
                    <Route path="carousel" element={AdminSwiper}></Route>
                    <Route path="works/:page" element={AdminWorks}></Route>
                    <Route path="work-editor/:id" element={WorkEditor}></Route>    
                    <Route path="works-buttons" element={WorksButtons}></Route>    
                    <Route path="categories" element={AdminCategories}></Route>    
                    <Route path="contact" element={AdminContact}></Route>    
                </Route>
            </Routes>
        </Router>
    )
}