import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './AppRouter';
import './index.scss';
import reportWebVitals from './reportWebVitals';

if (!Element.prototype.matches) {
  Element.prototype.matches =
    (Element.prototype as any).msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s:any) {
    var el = this as any;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

(HTMLElement.prototype as any).on=function(type:string,fn:(e:MouseEvent)=>void){
   var types=type.split(" "),
       i=types.length;
      while(i--){
        this.addEventListener(types[i],fn,false);
      }
      return this;
};

(HTMLElement.prototype as any).off=function(type:string,fn:(e:MouseEvent)=>void){
    var types=type.split(" "),
    i=types.length;
    while(i--){
      this.removeEventListener(types[i],fn,false);
    }
    return this;
};

if(!window.requestAnimationFrame){
  window.requestAnimationFrame=(window as any).webkitRequestAnimationFrame ||function(f){
    return setTimeout(f,16.667);
  };
  window.cancelAnimationFrame=(window as any).webkitCancelAnimationFrame || function(t){
    clearTimeout(t);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
